<template>
    <div class="line-item">
        <div class="line-text">
            <span>{{ data.title }}</span>
            <p class="line-data" v-if="data.total >= 0">
                <span
                    :class="{ error: data.num / data.total > 0.8, minwidth: data.num > 0 }"
                    :style="`width: ${data.num > data.total ? 100 : (data.num / data.total) * 100}%`"></span>
                <!-- <span v-else style="width: 50%" :class="data.num ? 'blue' : 'gray'"></span> -->
            </p>
            <span v-if="data.total >= 0">{{ `${data.num}/${data.total}` }}</span>
            <span v-else>{{ data.num }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'LineChartItem',
    props: {
        data: Object
    }
})
</script>

<style lang="less" scoped>
.line-item {
    .line-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
            &:first-child {
                margin-top: -2px;
                font-size: 18px;
                color: #000;
            }
            &:last-child {
                font-size: 14px;
                color: #666666;
            }
        }
    }
    .line-data {
        flex: 1;
        margin: 0 8px;
        height: 20px;
        border-radius: 4px;
        background-color: #eaeaea;
        min-width: 80px;
        span {
            height: 100%;
            display: inline-block;
            background-color: #18d22e;
            border-radius: 4px;
            max-width: 100%;
            &.error {
                background-color: #e62c35;
            }
            &.minwidth {
                min-width: 10px;
            }
            &.gray {
                background-color: #eaeaea;
            }
            &.blue {
                background-color: #2b32c9;
            }
        }
    }
}
</style>
