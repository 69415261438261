<template>
    <div class="contain-box">
        <CardBox>
            <template #tool-title>
                <div class="tab-bar">
                    <span :class="{ active: 'teamInfo' === currentTab }" @click="currentTab = 'teamInfo'">
                        {{ $t('user.basicInfo') }}
                    </span>
                    <span :class="{ active: 'SSHKey' === currentTab }" @click="currentTab = 'SSHKey'">
                        {{ t('user.publicKeyList') }}
                    </span>
                </div>
            </template>
            <template #contain>
                <div class="info-box" v-if="'teamInfo' === currentTab">
                    <template v-if="teamData">
                        <div class="info-title">
                            {{ teamData.trial ? t('teamVersion') : t('trialVersion') }}{{ valiateExpireTime(teamData.expireTime) ? `(${t('expired')})` : '' }}
                        </div>
                        <p class="info-item" v-if="teamData.trial">
                            <span>{{ t('resource.specification') }}</span>
                            <span>CPU-{{ teamData.specification }} RAM-{{ teamData.specification * 2 }}GB</span>
                        </p>
                        <p class="info-item" v-if="teamData.trial">
                            <span>{{ t('resource.storage') }}</span>
                            <span>{{ teamData.storage }}GB</span>
                        </p>
                        <p class="info-item">
                            <span>{{ t('resource.expireTime') }}</span>
                            <span>{{ moment(teamData.expireTime).format('YYYY-MM-DD') }}</span>
                        </p>
                        <p class="info-item">
                            <span>{{ t('resource.runningSpace') }}</span>
                            <span>{{ teamData.runningCount }}</span>
                        </p>
                        <LineChartItem class="data-line" :data="{ title: $t('resource.spaceNum'), num: teamData.usedCount, total: teamData.maxCount }" />
                    </template>
                    <Form
                        ref="teamNameForm"
                        :model="formData"
                        :rules="formRule"
                        :label-col-props="{ span: 1 }"
                        :wrapper-col-props="{ span: 23 }"
                        autocomplete="off">
                        <p class="form-title" style="margin-bottom: 10px">
                            {{ t('user.teamName') }}
                        </p>
                        <FormItem field="name" :isRequiredError="true" :hide-label="true" :validate-trigger="['blur']">
                            <Input v-model.trim="formData.name" spellcheck="false" :placeholder="t('holder.teamNameHolder')" />
                            <Button class="submit-btn btn-fill" type="primary" @click="editTeamInfo" :disabled="teamName === formData.name">
                                {{ t('save') }}
                            </Button>
                        </FormItem>
                        <p class="form-title" style="margin-bottom: 10px">
                            {{ t('user.ownership') }}
                        </p>
                        <FormItem :hide-label="true">
                            <span style="color: #666">{{ t('tip.ownershipTip') }}</span>
                            <Button class="submit-btn btn-fill" type="primary" @click="modalVisible = true">
                                {{ t('transfer') }}
                            </Button>
                        </FormItem>
                    </Form>
                </div>
                <Table
                    v-else
                    :class="['ssh-table', { 'hide-page-list': pageConf.total <= pageConf.pageSizeOptions[0] }]"
                    :data="formData.tableData"
                    :pagination="pageConf"
                    :scroll="{ y: 250 }"
                    :bordered="false"
                    :stripe="true">
                    <template #columns>
                        <TableColumn :title="t('user.name')" data-index="username" align="left" :width="140" ellipsis>
                            <template #cell="{ record }">
                                <span :title="record.username">{{ record.username }}</span>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('user.sshkey')" data-index="sshkey" align="left">
                            <template #cell="{ record }">
                                <span v-if="!record.publicKey">-</span>
                                <TypographyParagraph
                                    copyable
                                    ellipsis
                                    :copy-delay="200"
                                    @copy="copySuccess"
                                    :copy-text="record.publicKey"
                                    :title="record.publicKey"
                                    v-else>
                                    {{ record.publicKey }}
                                    <template #copy-icon="{ copied }">
                                        <icon-check-circle :style="{ fontSize: '20px' }" v-if="copied" />
                                        <icon-copy :style="{ fontSize: '20px', color: '#2b32c9' }" :stroke-width="6" v-else />
                                    </template>
                                    <template #copy-tooltip> {{ t('copy') }} </template>
                                </TypographyParagraph>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('user.createTime')" data-index="addTime" align="left" :width="200">
                            <template #cell="{ record }">
                                <span v-if="!record.createTime">-</span>
                                <span v-else>{{ moment(record.createTime).format('YYYY-MM-DD HH:mm') }}</span>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('operation')" align="center" :width="120">
                            <template #cell="{ record }">
                                <SvgIcon
                                    name="reset"
                                    :tip="t('user.resetKey')"
                                    color="#2b32c9"
                                    hoverColor="#2bc954"
                                    :size="20"
                                    @click="resetMemberSSH(record)" />
                            </template>
                        </TableColumn>
                    </template>
                </Table>
            </template>
        </CardBox>
        <Modal width="500px" v-model:visible="modalVisible" @close="closeModal" :mask-closable="false">
            <template #title>
                <p class="modal-title">{{ t('user.transferTeam') }}</p>
            </template>
            <div @keyup.enter="handleSubmit">
                <Form ref="ownershipForm" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                    <FormItem field="user" :validate-trigger="['blur']" :label="t('user.user')">
                        <Select :placeholder="t('holder.selectHolder')" v-model="formData.user">
                            <template v-for="item in userList" :key="item.userId">
                                <Option :value="item.userId">
                                    {{ item.username }}
                                </Option>
                            </template>
                        </Select>
                    </FormItem>
                    <FormItem field="password" :validate-trigger="['blur']" :label="t('login.password')">
                        <InputPassword v-model.trim="formData.password" spellcheck="false" :placeholder="t('holder.enterHolder')" :invisible-button="false" />
                    </FormItem>
                </Form>
            </div>
            <template #footer>
                <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                    {{ t('ok') }}
                </Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import CardBox from '@/components/CardBox.vue'
import { useRouter } from 'vue-router'
import { Form } from '@arco-design/web-vue'
import { useStore } from 'vuex'
import LineChartItem from '@/components/LineChartItem.vue'
import * as reg from '@/utils/formReg'
import { valiateExpireTime } from '@/utils/utils'
import Sha256 from 'crypto-js/sha256'
import { Role } from '@/store'
export default defineComponent({
    components: {
        CardBox,
        LineChartItem
    },
    setup() {
        const t = inject('$t')
        const modalVisible = ref(false)
        const http = inject('$http')
        const store = useStore()
        const router = useRouter()
        const msg = inject('$message')
        const modal = inject('$modal')
        const showSHHModal = ref(false)
        const moment = inject('$moment')
        const teamNameForm = ref(null || Form)
        const ownershipForm = ref(null || Form)
        const currentTab = ref('teamInfo')
        const teamData = ref(null)
        const teamName = ref('')
        const loading = ref(false)
        const userList = ref([])
        const formRule = {
            name: [{ required: true, match: reg.teamNameReg, message: t('tip.teamNameTip') }],
            password: [{ required: true, message: t('tip.emptyTip', { key: t('login.password') }) }],
            user: [{ required: true, message: t('tip.emptyTip', { key: t('user.user') }) }]
        }
        const formData = reactive({
            name: '',
            tableData: [],
            password: '',
            user: ''
        })
        http.account.getTeamDetail(store.state.userData.teamId).then(({ data }) => {
            formData.name = teamName.value = data.teamName
            getData()
            if (data.resources.length === 0) return
            teamData.value =
                data.resources.length === 1
                    ? data.resources[0]
                    : data.resources.filter((item) => {
                          return item.trial === 1
                      })[0]
        })
        const handleSubmit = () => {
            ownershipForm.value.validate((errors) => {
                if (!errors) {
                    const params = {
                        password: store.state.userData.from === 'info' ? formData.password : Sha256(formData.password).toString(),
                        userId: formData.user
                    }
                    http.account.moveTeam(params).then(() => {
                        msg.info(t('transferSucc'))
                        store.dispatch('getTeamsData')
                        const userData = { ...store.state.userData }
                        userData.role = Role.Developer
                        store.commit('setUserData', userData)
                        modalVisible.value = false
                        router.push('/project')
                    })
                }
            })
        }
        const closeModal = () => {
            formData.user = ''
            formData.password = ''
            ownershipForm.value.resetFields()
        }
        const editTeamInfo = () => {
            teamNameForm.value.validate((errors) => {
                if (!errors) {
                    http.account.editTeamInfo({ name: formData.name }).then(() => {
                        msg.info(t('saveSucc'))
                        teamName.value = formData.name
                        store.dispatch('getTeamsData')
                    })
                }
            })
        }
        const copySuccess = () => {
            msg.clear('top')
            msg.info(t('copied'))
        }
        const pageConf = reactive({
            showTotal: true,
            showPageSize: true,
            hideOnSinglePage: false,
            pageSizeOptions: [30, 60, 100],
            defaultPageSize: 30,
            current: 1,
            pageSize: 30,
            total: 0,
            onChange: (data) => {
                pageConf.current = data
                getData()
            },
            onPageSizeChange: (data) => {
                pageConf.current = 1
                pageConf.pageSize = data
                getData()
            }
        })
        const getData = () => {
            const params = {
                teamId: store.state.userData.teamId,
                page: pageConf.current - 1,
                size: pageConf.pageSize
            }
            http.project.getSSHkey(params).then(({ data }) => {
                pageConf.total = data.total
                formData.tableData = data.data
                const table = document.querySelector('.arco-table-body')
                if (table) {
                    table.scrollTop = 0
                }
            })
        }

        http.project
            .getAllSSHkey({
                teamId: store.state.userData.teamId
            })
            .then(({ data }) => {
                userList.value = data
            })

        const resetMemberSSH = (item) => {
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t('tip.resetSSHKeyTip', { username: item.username }),
                onOk: () => {
                    http.account.resetMemberSSH(item.userId).then(() => {
                        pageConf.current = 1
                        getData()
                        msg.info(t('updateSucc'))
                    })
                }
            })
        }
        return {
            valiateExpireTime,
            loading,
            modalVisible,
            handleSubmit,
            currentTab,
            formData,
            formRule,
            teamName,
            teamNameForm,
            ownershipForm,
            userList,
            t,
            teamData,
            showSHHModal,
            editTeamInfo,
            pageConf,
            moment,
            resetMemberSSH,
            copySuccess,
            closeModal
        }
    }
})
</script>
<style lang="less" scoped>
.contain-box {
    .info-box {
        padding: 40px 0px;
        width: 580px;
        margin: auto;
        .data-line {
            margin-bottom: 20px;
            :deep(.line-data) {
                margin: 0 10px;
            }
            :deep(.line-text > span:nth-child(1)) {
                color: #333;
                width: 40%;
                text-align: right;
                padding-right: 10px;
            }
            :deep(.line-text > span:last-child) {
                width: 200px;
                display: inline-block;
            }
        }
        .info-title {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            color: #333;
            margin-bottom: 30px;
        }
        .info-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            font-size: 18px;
            color: #333;
            span:nth-child(1) {
                width: 40%;
                text-align: right;
                padding-right: 10px;
            }
            span:nth-child(2) {
                width: 60%;
                color: #666;
                font-size: 14px;
                padding-left: 10px;
                line-height: 22px;
            }
        }
        .arco-form {
            padding-bottom: 20px;
        }
        .form-title {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333;
        }
        .form-tip {
            font-size: 14px;
            color: #666;
            margin-bottom: 6px;
            span {
                color: #3235e8;
                cursor: pointer;
            }
        }
        .submit-btn {
            margin-left: 10px;
        }
    }
    .ssh-table {
        width: 900px;
        padding: 60px 0;
        margin: 0 auto;
    }
    :deep(.arco-typography-operation-copied),
    :deep(.arco-typography-operation-copy) {
        vertical-align: middle !important;
        display: inline-block;
        height: 20px;
        margin-top: -5px;
    }
    :deep(.svg-icon) {
        margin-top: -4px;
    }
}
</style>
